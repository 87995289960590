<template>
  <div>

    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>

    <div class="chat-header">

      <v-skeleton-loader
          v-if="getRequestLoadStatus !== 2"
          class="mt-5"
          type="list-item-avatar-two-line"
      ></v-skeleton-loader>

      <div v-else class="px-5 pt-5 d-flex">
        <div>
          <v-img
              class="mr-4 preview-image"
              max-width="90px"
              :src="getRequest.user_profile_picture"
          ></v-img>
        </div>

        <div class="pt-2">
          <div class="text--primary body-1">
            {{ getRequest.user_name }}
          </div>
          <div class="text-11-px text--secondary">
            {{ getRequest.vendor_name }}
          </div>
        </div>

        <div class="ml-auto font-weight-bold">
          <v-select
              @change="changeStatus"
              class="pl-2"
              v-model="form.status"
              dark
              item-text="text"
              item-value="value"
              solo
              dense
              label="Qualification"
              :items="[{text: 'Confirmed', value: 'confirmed'},{text: 'In Process', value: 'in_process'},{text: 'Declined', value: 'declined'},{text: 'No Reply', value: 'no_reply'},]"
          ></v-select>
        </div>

      </div>
    </div>

    <v-divider></v-divider>

    <div class="body-height px-5" v-on:scroll.passive="handleScroll">

      <div v-if="getRequestLoadStatus !== 2">
        <v-skeleton-loader
            v-for="n in 8"
            :key="n"
            class="mt-5"
            type="list-item-avatar-three-line"
        ></v-skeleton-loader>
      </div>

      <chat-message
          v-else
          v-for="(message, index) in conversation"
          :key="index"
          class="my-3"
          :recipient="getRecipient(message)"
          :message="message"
      ></chat-message>

      <div id="scrollTarget" ref="scrollTarget"></div>
      <v-btn
          v-if="hasScrolledToBottom === false"
          class="scroll-button"
          fab
          x-small
          color="primary"
          @click="scrollToBottom"
      >
        <v-icon dark>
          mdi-chevron-double-down
        </v-icon>
      </v-btn>

    </div>

    <div class="card-footer pt-0 px-5">
      <v-text-field
          @keyup.enter="send"
          solo
          label="Type a message here"
          height="52"
          class="pt-5 send-box"
          v-model="message"
      >
        <template v-slot:prepend-inner>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                  @click="attachment"
                  v-on="on"
                  fab
                  elevation="0"
                  dark
                  small
                  class="pa-2 mr-3"
              >
                <v-icon small>mdi-attachment</v-icon>
              </v-btn>
            </template>
            Attachment
          </v-tooltip>
        </template>

        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  fab
                  elevation="0"
                  dark
                  small
                  class="pa-2"
                  @click="send"
              >
                <v-icon small>mdi-send</v-icon>
              </v-btn>
            </template>
            Send
          </v-tooltip>
        </template>
      </v-text-field>
    </div>

  </div>
</template>

<script>
const ChatMessage = () => import(/* webpackChunkName: "chat-message" */'./Message');
import { mapGetters, mapActions, mapMutations } from 'vuex';
const Snackbar = () => import(/* webpackChunkName: "snackbar" */'@/components/Snackbar');
import moment from 'moment';
export default {
  name: "ChatBody",
  components: { ChatMessage, Snackbar },
  data() {
    return {
      message: null,
      autoMessage: null,
      hasScrolledToBottom: false,
      form: new Form(),
      snackbarMessage: '',
    };
  },
  computed: {
    conversation() { return this.getConversation; },
    ...mapGetters(['getRequestLoadStatus', 'getRequest', 'getConversation', 'getAuthUser']),
  },
  mounted() {
    setTimeout(() => {
      this.initForm();
    }, 600);
  },
  methods: {
    userIds() {
      return _.uniq(_.reject(_.reject(_.map(this.getConversation, (message) => {
        if ( message.sender.id !== this.getAuthUser.id ) {
          return message.sender.id;
        }
      }), _.isNull), _.isUndefined));
    },
    async initForm() {
      this.form = new Form(this.getRequest);
    },
    async changeStatus() {
      let request = Object.assign({}, this.form);
      this.setRequest(request);
      await this.updateRequest({id: request.id});

      this.snackbarMessage = "Request status has been changed successfully.";
      this.$refs.snackbar.type = "success";
      this.$refs.snackbar.show = true;

    },
    getRecipient(message={}) {
      let recipient = 'receiver';
      if ( message.automatic_reply !== undefined ) {
        return recipient;
      }
      if ( message.sender === undefined || message.sender.id === this.getAuthUser.id ) { recipient = 'sender'; }
      return recipient;
    },
    handleScroll(e) {
      if ( e.target.scrollHeight-1000 <= e.target.scrollTop ) { this.hasScrolledToBottom = true; }
      else { this.hasScrolledToBottom = false; }
    },
    scrollToBottom() {
      this.$vuetify.goTo(this.$refs.scrollTarget, {container: ".body-height"});
      this.hasScrolledToBottom = true;
    },
    async send() {
      if ( this.message.trim() === '' ) {
        this.message = null;
      }
      else {
        let timestamp = moment().valueOf();
        this.setNewMessage({
          id: null,
          conversation_id: this.getRequest.conversation_id,
          user_id: this.getAuthUser.id,
          body: this.message,
          sender: {
            profile_picture: 'storage/dummy/user.png',
          },
          mlsilent: true,
          timestamp: timestamp,
        });
        this.scrollToBottom();
        await this.sendRequestMessage();
        this.autoMessage = this.message;
        this.message = null;
        setTimeout(() => {
          this.automatedReply();
        }, 1000);
      }
    },
    automatedReply() {
      let timestamp = moment().valueOf();
      this.setNewMessage({
        id: null,
        conversation_id: this.getRequest.conversation_id,
        user_id: _.sample(this.userIds()),
        body: this.autoMessage,
        mlsilent: true,
        automatic_reply: true,
        sender: {
          full_name: 'System Reply',
          profile_picture: 'storage/dummy/user.png',
        },
        timestamp: timestamp,
      });
      this.scrollToBottom();
      this.sendRequestMessage();
      this.autoMessage = null;
    },
    attachment() {
      alert('Attachment');
    },
    ...mapActions(['sendRequestMessage', 'updateRequest']),
    ...mapMutations(['setNewMessage', 'setRequest']),
  },
  watch: {
    getRequestLoadStatus(value) {
      if ( value === 2 ) {
        this.initForm();
        setTimeout(() => { this.scrollToBottom(); }, 200); }
      },
  },
}
</script>
<style scoped>
.send-box {
  border-radius: 25px;
}
.card-footer {
  height: 100px;
}
.body-height {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
.preview-image{
  border-radius: 50px;
  height: 60px;
  width: 60px;
}
.text-11-px {
  font-size: 11px;
}
.chat-header, .card-footer {
  border-right: thin solid #d9d9d9;
}
.scroll-button {
  position: absolute;
  right: 32%;
  bottom: 135px;
}
</style>